import { Node, NodeHelpersType, NodeTree, SerializedNode, SerializedNodes } from '@craftjs/core';
import { DataType } from './uiEditor';
import { getRandomId } from '@craftjs/utils';
import { CommentDataKey, CommentDataKeyRegex, DataBlockValidation, Page, PageStack } from './provider/formFill.context';
import { createId } from 'src/helpers/typeUtils';
import { IFormFieldWithOptionsProps } from 'src/features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';
import { isNullOrEmpty } from 'src/helpers/string-helper';
import { convertDateToString } from 'src/helpers/date-helpers';
import { IPatientDto } from 'src/services/api/api-client';
import { IImageBlockProps } from 'src/features/forms/base/controls/content/ImageBlock';

// This function convert page tree:
// Tab1 -- Tab2 [Step1 - Step2 - Step3] -- Tab3
// to list:
// [[Tab1], [Tab2, Step1], [Tab2, Step2], [Tab2, Step3], [Tab3]]
export const convertLayoutToPageList = (layout: SerializedNodes) => {
  const pages: PageStack[] = [];
  let blocks: {
    [blockId: string]: {
      pages?: PageStack;
      ref: any;
    };
  } = {};

  const findSteps = (nodeId: string, subPages?: Page[]) => {
    let page: PageStack = [];

    // @ts-ignore
    if (layout[nodeId].type.resolvedName === 'TabsContainer' || layout[nodeId].type.resolvedName === 'StepContainer') {
      Object.keys(layout[nodeId].linkedNodes).forEach((node, i) => {
        page = [...(subPages ?? []), { nodeId: nodeId, pageNumber: i + 1 }];
        const lengthBefore = pages.length;
        findSteps(layout[nodeId].linkedNodes[node], page);

        // Prevent double adding page to list
        if (lengthBefore === pages.length) {
          pages.push(page);
        }
      });
    } else {
      layout[nodeId].nodes.forEach((node) => {
        blocks = { ...blocks, [node]: { pages: subPages, ref: null } };
        findSteps(node, subPages);
      });

      Object.entries(layout[nodeId].linkedNodes).forEach(([_, _nodeId]) => {
        blocks = { ...blocks, [_nodeId]: { pages: subPages, ref: null } };
        findSteps(_nodeId, subPages);
      });
    }
  };

  findSteps('ROOT', undefined);
  return { pages, blocks };
};

/** This function remove additional part from NodeId or DataKey
 * @example _from in  dataKey_from */
export const normalizeDataKey = (nodeId: string) => {
  return nodeId.replace('_from', '').replace('_until', '');
};

export const findNodeIdsWithDataKey = (layout: SerializedNodes, dataKey: string): string[] => {
  return Object.keys(layout).filter((key) => layout[key].props.dataKey === normalizeDataKey(dataKey));
};

export const findNodeWithDataKey = (layout: SerializedNodes, dataKey: string): SerializedNode | undefined => {
  return layout[findNodeIdsWithDataKey(layout, dataKey)?.[0]];
};

export const scrollToRef = (ref: any) => {
  if (ref?.current) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    ref?.current?.scrollIntoView(true);
  }
};

export const isValid = (data: DataBlockValidation) => {
  return Object.keys(data).every((key) => data[key].validationState === 'Ok');
};

/**
 * Returns answers inherited from the previously filled form
 * @param layout form layout to understand which answers should be inherited
 * @param results answers from the previous filled form of the same type
 * @returns
 */
export const getValuesFromFilledRecords = (
  layout: SerializedNodes,
  results?: DataType | undefined,
): DataType | undefined => {
  if (!results) {
    return;
  }
  const resultValues = { ...results };

  Object.keys(layout)
    .filter((nodeKey) => layout[nodeKey].props?.ignoreInitialValuesFromPreviousForm)
    .forEach((nodeKey) => {
      const dataKeyRelatedFields = Object.keys(resultValues).filter(
        (x) => x === layout[nodeKey].props.dataKey || x.includes(`${layout[nodeKey].props.dataKey}_`),
      );

      for (const dataKeyRelatedFieldsKey of dataKeyRelatedFields) {
        delete resultValues[dataKeyRelatedFieldsKey];
      }
    });

  return resultValues;
};

/**
 * Return default values from form config
 * @param layout form layout
 */
export const getDefaultValuesFromLayout = (layout: SerializedNodes, patient: IPatientDto | undefined) =>
  Object.values(layout)
    .filter((x) => !isNullOrEmpty(x.props.dataKey))
    .reduce((values, x) => {
      let result = values;

      const isEmpty =
        x.props.defaultValue === undefined ||
        (Array.isArray(x.props.defaultValue) && !x.props.defaultValue.length) ||
        (typeof x.props.defaultValue === 'string' && isNullOrEmpty(x.props.defaultValue));
      if (!isEmpty) {
        result = {
          ...result,
          [x.props.dataKey]: x.props.defaultValue,
        };
      }

      if (x.props.dataKey === CONST_DATA_KEYS.UniqueId && !!patient?.patientId) {
        result = {
          ...result,
          [x.props.dataKey]: patient.patientId,
        };
      }

      if (x.props.defaultValueAsNow) {
        result = {
          ...result,
          [x.props.dataKey]: convertDateToString(new Date()),
        };
      }

      if (x.props.defaultValueFromIsNow) {
        result = {
          ...result,
          [`${x.props.dataKey}_from`]: convertDateToString(new Date()),
        };
      }

      if (x.props.defaultValueUntilIsNow) {
        result = {
          ...result,
          [`${x.props.dataKey}_until`]: convertDateToString(new Date()),
        };
      }

      return result;
    }, {});

/**
 * Clone node
 * @param query - query from the {@link useEditor useEditor} hook
 * @param source - id of the node or {@link NodeTree NodeTree} object
 * @returns new {@link NodeTree NodeTree} object
 */
export const getCloneTree = (query: any, source: string | NodeTree): NodeTree => {
  const tree = typeof source === 'object' ? source : query.node(source).toNodeTree();
  const newNodes: any = {};

  const changeNodeId = (node: Node, newParentId?: string, newParentDataKey?: string) => {
    const newId = getRandomId();
    const newDataKey = generateNewDataKey(node.data.props.dataKey, newParentDataKey);

    const childNodes = node.data.nodes.map((childId: string) => changeNodeId(tree.nodes[childId], newId, newDataKey));
    const linkedNodes = Object.keys(node.data.linkedNodes).reduce((accum, id) => {
      const newNodeId = changeNodeId(tree.nodes[node.data.linkedNodes[id]], newId, newDataKey);
      return {
        ...accum,
        [id]: newNodeId,
      };
    }, {});

    const tmpNode = {
      ...node,
      id: newId,
      data: {
        ...node.data,
        parent: newParentId || node.data.parent,
        nodes: childNodes,
        linkedNodes,
        props: { ...node.data.props, dataKey: newDataKey },
      },
    };

    const freshNode = query.parseFreshNode(tmpNode).toNode();
    newNodes[newId] = freshNode;
    return newId;
  };

  const rootNodeId = changeNodeId(tree.nodes[tree.rootNodeId]);
  return {
    rootNodeId,
    nodes: newNodes,
  };
};

const generateNewDataKey = (dataKey: string | undefined, newParentDataKey?: string) => {
  if (!dataKey) return undefined;
  if (isConstDataKey(dataKey)) return dataKey;
  if (isFileCommentDataKey(dataKey)) return CommentDataKey(newParentDataKey ?? createId());

  return createId();
};

export const pageStacksIsEqual = (first: PageStack | undefined, second: PageStack | undefined) => {
  return JSON.stringify(first) === JSON.stringify(second);
};

export const isFieldWithOptions = (fieldProps: any): fieldProps is IFormFieldWithOptionsProps => {
  return fieldProps.options && fieldProps.options.length > 0;
};

export const isNotEmptyImageBlock = (fieldProps: any): fieldProps is IImageBlockProps => {
  return !isNullOrEmpty(fieldProps.fileId);
};

export const layoutToDataKeyFlatList = (layout: SerializedNodes): string[] => {
  const recursive = (nodeId: string): string[] => {
    const linkedNodes = Object.values(layout[nodeId].linkedNodes).reduce(
      (arr: string[], curNodeId) => [...arr, layout[curNodeId].props.dataKey, ...recursive(curNodeId)],
      [],
    );

    const nodes = layout[nodeId].nodes.reduce(
      (arr: string[], curNodeId) => [...arr, layout[curNodeId].props.dataKey, ...recursive(curNodeId)],
      [],
    );

    return [...linkedNodes, ...nodes].filter(Boolean);
  };

  return recursive('ROOT');
};

export const CONST_DATA_KEYS = {
  UniqueId: 'UniqueId',
  DeviceSerialNumber: 'DeviceSerialNumber',
  ContractSignedAt: 'ContractSignedAt',
  StudyStartedAt: 'StudyStartedAt',
  GroupId: 'GroupId',
  RecordResultList: 'RecordResultList',
};

export const isConstDataKey = (dataKey: string) =>
  Object.values(CONST_DATA_KEYS)
    .map((key) => key.toLowerCase())
    .includes(dataKey.toLowerCase());

export const isFileCommentDataKey = (dataKey: string) => CommentDataKeyRegex.test(dataKey);

export const getNodesWithSameDataKeys = (serializedNodes: Record<string, Node>): Record<string, Node[]> => {
  const groupedNodesByDataKey = Object.entries(serializedNodes)
    .filter(([, node]) => !!node.data.props.dataKey && isConstDataKey(node.data.props.dataKey))
    .reduce(
      (obj: Record<string, Node[]>, [, node]): Record<string, Node[]> => ({
        ...obj,
        [node.data.props.dataKey]: [...(obj?.[node.data.props.dataKey] ?? []), node],
      }),
      {},
    );

  return Object.entries(groupedNodesByDataKey)
    .filter(([, nodes]) => nodes.length > 1)
    .reduce((obj, [dataKey, nodes]) => ({ ...obj, [dataKey]: nodes }), {});
};

export const getNodeByDataKey = (
  nodeList: Record<string, string> | string[],
  dataKey: string,
  helper: NodeHelpersType,
): Node | undefined => {
  for (const key in nodeList) {
    if (!isNumberString(key)) return;
    let node = Array.isArray(nodeList) ? helper(nodeList[Number(key)]).get() : helper(nodeList[Number(key)]).get();
    if (node.data.props.dataKey === dataKey) {
      return node;
    } else if (Object.keys(node.data.linkedNodes).length) {
      const foundNode = getNodeByDataKey(node.data.linkedNodes, dataKey, helper);
      if (foundNode) {
        return foundNode;
      }
    } else if (node.data.nodes.length) {
      const foundNode = getNodeByDataKey(node.data.nodes, dataKey, helper);
      if (foundNode) {
        return foundNode;
      }
    }
  }
  return undefined;
};

const isNumberString = (value: string): boolean => {
  const number = parseFloat(value);
  return !isNaN(number);
};
