import { Callout, DirectionalHint } from '@fluentui/react';
import clsx from 'clsx';
import React, { useCallback, useLayoutEffect, useState, FC } from 'react';
import { TypographyStyles } from 'src/styles';
import { AppButton } from 'uikit/buttons';
import { DropDownMenuOption, DropDownMenuProps } from './dropDownMenu';
import { useId } from '@fluentui/react-hooks';
import Style from './dropDownMenu.module.css';
import { ReactComponent as Chevron } from '../../../assets/img/common/chevron.svg';

export const DropDownMenu: FC<DropDownMenuProps> = (props) => {
  const {
    closeCalloutByClick = true,
    directionalHint,
    options,
    disabled,
    variant = 'icon-link',
    buttonClassName,
    testId,
    counter,
    hasChevron,
    controlClassName,
    onExpendChange,
    calloutHeader,
    calloutFooter,
    calloutClassName,
    calloutStyles,
    customComponent,
    ...rest
  } = props;
  const [expanded, setExpended] = useState<boolean>(false);
  const calloutButtonId = useId('callout-button');

  // This id is used to find dropdown options in UI tests
  const calloutId = useId();

  useLayoutEffect(() => {
    onExpendChange?.(expanded);
  }, [expanded]);

  const onMouseDown = useCallback(
    (item: DropDownMenuOption) => {
      closeCalloutByClick && setExpended(false);
      item.action();
    },
    [closeCalloutByClick],
  );

  return (
    <div>
      <div
        id={calloutButtonId}
        data-test-id={testId}
        aria-owns={expanded ? calloutId : undefined}
        className={clsx(Style.buttonWrapper, { [Style.buttonWithChevron]: hasChevron }, controlClassName)}
        onClick={(e) => {
          e.stopPropagation();
          !disabled && setExpended(!expanded);
        }}
      >
        {customComponent ?? (
          <>
            {/* @ts-ignore */}
            <AppButton
              variant={variant}
              className={clsx(buttonClassName, { [Style.visibility]: expanded })}
              {...rest}
              disabled={disabled}
            />
            {counter && <span className={Style.counter}>{counter}</span>}
            {hasChevron && <Chevron className={clsx(Style.chevron, { [Style.rotated]: !expanded })} />}
          </>
        )}
      </div>

      {expanded && (
        <Callout
          id={calloutId}
          onDismiss={() => setExpended(false)}
          beakWidth={0}
          gapSpace={4}
          target={`#${calloutButtonId}`}
          directionalHint={directionalHint ?? DirectionalHint.bottomRightEdge}
          className={clsx(Style.callout, calloutClassName)}
          style={{ overflow: 'hidden', display: 'grid', maxHeight: '60vh', ...calloutStyles }}
        >
          {calloutHeader && <div className={Style.calloutHeader}>{calloutHeader}</div>}
          <div data-test-id={'menu-itemList'} className={Style.optionsContainer}>
            {options.map((item) => (
              <div
                data-test-id={'menu-item'}
                key={item.key}
                className={clsx(Style.item, TypographyStyles.paragraph14, item.className)}
                onClick={(e) => {
                  e?.stopPropagation();
                  onMouseDown(item);
                }}
              >
                {item.icon && <div className={Style.icon}>{item.icon}</div>}
                <div>{item.text}</div>
              </div>
            ))}
          </div>
          {calloutFooter && <div className={Style.calloutFooter}>{calloutFooter}</div>}
        </Callout>
      )}
    </div>
  );
};
